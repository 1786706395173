<template>
  <div class="convert">
    <header-bar :showNotice="false" :showBack="true" :transparent="true"></header-bar>

    <div style="margin-top: -90px;">
      <title-name title="闪兑" label="Flash exchange"></title-name>
    </div>

    <div class="form">
      <div class="form-item">
        <div class="form-label">当前币价</div>
      </div>
      <div class="form-content">
        <div>1 CCAI = {{ swapPrice }} USDA</div>
      </div>
    </div>

    <div class="form">
      <div class="form-item">
        <div class="form-label">已授权</div>
      </div>
      <div class="form-content">
        <div>USDA: {{ allowances.usde }}</div>
        <div class="q-mt-xs">CCAI : {{ allowances.eds }}</div>
      </div>
    </div>

    <div class="form">
      <div class="form-item">
        <div class="form-label">闪兑</div>
        <div class="input-wrap">
          <q-input borderless v-model="approveAmount" placeholder="请输入授权金额">
          </q-input>
        </div>
        <div class="btn-wrap q-mb-lg">
          <div class="btn" @click="approve(2)">授权USDA</div>
          <div class="btn" @click="approve(3)">授权CCAI</div>
        </div>
        <div class="input-wrap">
          <q-input borderless v-model="jine" placeholder="请输入闪兑金额">
          </q-input>
        </div>
      </div>
      <div class="balance">
        <div>USDA{{ balance.usde }}</div>
        <div>CCAI：{{ balance.eds }}</div>
      </div>
      <div class="btn-wrap">
        <div class="btn" @click="swap(1)">USDA闪兑CCAI</div>
        <div class="btn" @click="swap(2)">CCAI闪兑USDA</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'

import Web3Helper from "../../assets/js/web3helper.js";

export default {
  name: '',
  components: {
    HeaderBar,
    TitleName
  },
  setup() {
    return {
      us: ref(null),
      jine: ref(""),
      approveAmount: ref(""),

      web3SWAP: ref({}),
      web3USDT: ref({}),
      web3USDE: ref({}),
      web3EDS: ref({}),

      swapPrice: ref(0),

      balance: ref({
        usdt: 0,
        usde: 0,
        eds: 0
      }),

      allowances: ref({
        usdt: 0,
        usde: 0,
        eds: 0
      }),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getInfo()
  },
  methods: {
    getInfo() {
      let _this = this
      _this.$request.post(
        "api/EthContract/Info",
        {},
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
          let data = res.data.data

          var swap = data.contractList.find(item => item.id == 99);
          var usdt = data.contractList.find(item => item.id == 11);
          var usde = data.contractList.find(item => item.id == 12);
          var eds = data.contractList.find(item => item.id == 13);

          _this.web3SWAP = new Web3Helper(swap.abi, swap.address);
          _this.web3USDT = new Web3Helper(usdt.abi, usdt.address);
          _this.web3USDE = new Web3Helper(usde.abi, usde.address);
          _this.web3EDS = new Web3Helper(eds.abi, eds.address);

          // _this.balanceOf(swap.address, 4);
          // _this.balanceOf(swap.address, 5);

          _this.balanceOf(_this.us.address, 1);
          _this.balanceOf(_this.us.address, 2);
          _this.balanceOf(_this.us.address, 3);

          _this.allowance(_this.us.address, 1);
          _this.allowance(_this.us.address, 2);
          _this.allowance(_this.us.address, 3);

          _this.getPrice();
        }
      )
    },
    // 获取 SWAP 币价
    getPrice() {
      let _this = this
      _this.web3SWAP.getPrice().then((res) => {
        _this.swapPrice = _this.web3SWAP.fromWei(res, 18);
      });
    },
    // 获取余额
    balanceOf(address, lx) {
      let _this = this;
      if (lx == 1) {
        _this.web3USDT.contractBalanceOf(address).then((wei) => {
          _this.balance.usdt = _this.web3USDT.fromWei(wei, 18);
        });
      } else if (lx == 2) {
        _this.web3USDE.contractBalanceOf(address).then((wei) => {
          _this.balance.usde = _this.web3USDE.fromWei(wei, 18);
        });
      } else if (lx == 3) {
        _this.web3EDS.contractBalanceOf(address).then((wei) => {
          _this.balance.eds = _this.web3EDS.fromWei(wei, 18);
        });
      } else if (lx == 4) {
        _this.web3USDE.contractBalanceOf(address).then((wei) => {
          _this.pool.usde = _this.web3USDE.fromWei(wei, 18);
        });
      } else if (lx == 5) {
        _this.web3EDS.contractBalanceOf(address).then((wei) => {
          _this.pool.eds = _this.web3EDS.fromWei(wei, 18);
        });
      }
    },
    // 获取已授权金额
    allowance(address, lx) {
      let _this = this
      if (lx == 1) {
        _this.web3USDT.contractAllowance(address, _this.web3SWAP.contractAddress).then((wei) => {
          _this.allowances.usdt = _this.web3USDT.fromWei(wei, 18);
        });
      } else if (lx == 2) {
        _this.web3USDE.contractAllowance(address, _this.web3SWAP.contractAddress).then((wei) => {
          _this.allowances.usde = _this.web3USDE.fromWei(wei, 18);
        });
      } else if (lx == 3) {
        _this.web3EDS.contractAllowance(address, _this.web3SWAP.contractAddress).then((wei) => {
          _this.allowances.eds = _this.web3EDS.fromWei(wei, 18);
        });
      }
    },
    // 执行授权
    approve(lx) {
      let _this = this;
      if (_this.approveAmount == "") {
        _this.$q.dialog({
          message: "请输入授权金额",
        });
        return
      }
      if(lx == 2 && _this.approveAmount > _this.balance.usde) {
        _this.$q.dialog({
          message: "余额不足",
        });
        return
      }
      if(lx == 3 && _this.approveAmount > _this.balance.eds) {
        _this.$q.dialog({
          message: "余额不足",
        });
        return
      }

      let payWei = _this.web3USDT.toWei(_this.approveAmount, 18);

      var web3Contract;
      if (lx == 1) {
        web3Contract = _this.web3USDT;
      } else if (lx == 2) {
        web3Contract = _this.web3USDE;
      } else if (lx == 3) {
        web3Contract = _this.web3EDS;
      }

      _this.$q.loading.show({
        message: _this.$t('等待交易完成')
      })
      web3Contract.contractApprove(_this.us.address, _this.web3SWAP.contractAddress, payWei.toString()).then((txid) => {
        _this.$q.loading.hide();
        _this.$q.dialog({
          message: "授权成功",
        });
        _this.balanceOf(_this.us.address, lx);
        _this.allowance(_this.us.address, lx);
      }, (err) => {
        _this.$q.loading.hide();
        //过滤4001,4001=拒绝签名
        if (err.code != 4001) {
          _this.$q.dialog({
            title: err.code,
            message: err.message,
          });
        }
      });
    },
    swap(lx) {
      let _this = this
      if (_this.jine == "") {
        _this.$q.dialog({
          message: "请输入闪兑金额",
        });
        return
      }
      let payWei = _this.web3SWAP.toWei(_this.jine, 18);
      _this.$q.loading.show({
        message: _this.$t('等待交易完成')
      })
      if (lx == 1) {
        _this.web3SWAP.contractUsdeExchangeEds(_this.us.address, payWei.toString()).then((txid) => {
          _this.$q.loading.hide();
          _this.$q.dialog({
            message: "闪兑成功",
          });
          _this.balanceOf(_this.us.address, 2);
          _this.balanceOf(_this.us.address, 3);
        }, (err) => {
          _this.$q.loading.hide();
          //过滤4001,4001=拒绝签名
          if (err.code != 4001) {
            _this.$q.dialog({
              title: err.code,
              message: err.message,
            });
            _this.balanceOf(_this.us.address, 2);
            _this.balanceOf(_this.us.address, 3);
          }
        });
      } else if (lx == 2) {
        _this.web3SWAP.contractEdsExchangeUsde(_this.us.address, payWei.toString()).then((txid) => {
          _this.$q.loading.hide();
          _this.$q.dialog({
            message: "闪兑成功",
          });
        }, (err) => {
          _this.$q.loading.hide();
          //过滤4001,4001=拒绝签名
          if (err.code != 4001) {
            _this.$q.dialog({
              title: err.code,
              message: err.message,
            });
          }
        });
      }
    }
  }
}
</script>

<style scoped>
.convert {
  min-height: 100vh;
  background: url('~@/assets/img/base/banner-background.jpg') no-repeat;
  background-size: 100% 100%;
}

.form {
  margin: 20px 12px 0;
  padding: 15px;
  border-radius: 12px;
  background-color: #fff;
}

.input-wrap {
  padding: 0 16px;
  border-radius: 10px;
  background-color: #f1f1f1;
}

.input-wrap img {
  width: 24px;
}

.form-label {
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.btn {
  width: 47%;
  margin: 20px 0 0;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  border-radius: 14px;
  color: #fff;
  background-color: #6EADFD;
}

.footer-tip {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  margin-top: 10px;
  color: gray;
}

.coin-price {
  margin-top: 10px;
  text-align: right;
}

.btn-wrap {
  display: flex;
  justify-content: space-between;
}

.balance {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
</style>